import { environment } from './../../environments/environment';
import { Inject, PLATFORM_ID, Injectable } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
//consumes the app insights SDK, and this is TS wrapper around Javascript API


@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: '6e15a750-72fa-4cc1-af55-c9821c80076d',
      // instrumentationKey: environment.appInsights.instrumentationKey,
      enableAutoRouteTracking: true,
    },
  });

  //used in app.component.ts to track each route using the 'title' value added in app-routing.module.ts
  constructor(@Inject(PLATFORM_ID) private _platformId: Object) {
    if (isPlatformBrowser(this._platformId)) {
      this.appInsights.loadAppInsights();
    }
  }

  //added 'isPlatformBrowser 11-13-21 by Siddharth Ajmera to fix the npm run prerender failing.  This fixed pipeline issue in dev.azure.com

  logPageView(name?: string, uri?: string) {
    if (isPlatformBrowser(this._platformId))
      this.appInsights.trackPageView({ name, uri });
  }

  logEvent(name: string, properties?: any, measurements?: any) {
    if (isPlatformBrowser(this._platformId))
      this.appInsights.trackEvent({ name, properties, measurements });
  }
}
